.graph {
  margin: 20px;
}

.CountUp {
  font-size: 2em;
  font-weight: 500;
}

.stat {
  margin: 5px;
}

.stat h3 {
  font-size: 1.5em;
}

.section {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.download-button {
  border-radius: 5px;
  background-color: #fff;
  border: solid 1px #aaa;
  font-size: 1.2em;
  margin: 10px;
  padding: 5px;
  transition: 0.2s;
  color: black;
  text-decoration: none;
}

.download-button:hover {
  background-color: #fafafa;
  border-color: #444;
}
