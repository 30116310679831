.Main {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  font-size: 1.2em;
  padding: 0% 2%;
  height: 100%;
  min-height: 100vh;
}

button {
  border-radius: 5px;
  background-color: #fff;
  border: solid 1px #aaa;
  font-size: 1.2em;
  margin: 10px;
  padding: 5px;
  transition: 0.2s;
}

button:hover {
  background-color: #fafafa;
  border-color: #444;
}

button.red {
  background-color: rgb(204, 117, 111);
}

button.primary {
  background-color: rgb(135, 214, 135);
}

select,
input {
  background-color: #fff;
  border: 1px solid #999;
  font-size: 1em;
  border-radius: 3px;
  transition: 0.2s;
  font-family: "Roboto", sans-serif;
  flex-shrink: 1;
  width: 20ch;
}

input:invalid {
  border: 2px solid red;
}

textarea {
  background-color: #fff;
  border: 1px solid #999;
  font-size: 1em;
  border-radius: 3px;
  transition: 0.2s;
  min-height: 4ch;
  font-family: "Roboto", sans-serif;
}

select:hover,
input:hover {
  border-color: #444;
}

.colis-edition {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
}

@media (max-width: 1500px) {
  .colis-edition {
    flex-wrap: wrap;
  }
}

.table-overflow {
  overflow: auto;
  max-height: 80vh;
  flex-shrink: 1;
  flex-grow: 2;
  width: 65vw;
}

.form-fields {
  display: flex;
  flex-wrap: wrap;
}

.form-input {
  display: flex;
  flex-direction: column;
  margin: 10px;
  justify-content: flex-start;
}

.form-input label {
  font-size: 1.1em;
  text-align: left;
}

.filter-checkbox {
  width: 25px;
}
