.searchbar {
  display: flex;
  flex-direction: row;
  width: 35em;
  font-size: 1.3em;
  height: 1.5em;
  background-color: #fff;
  border-radius: 0.5em;
  box-shadow: 0px 0px 5px var(--darker-primary-color);
  margin: 0.5em;
}

.searchbar input {
  border: none;
  border-radius: 0;
  width: 100%;
  border-radius: 0 0.5em 0.5em 0;
}

.searchbar input:active {
  border: none;
}

.select-dropdown {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #fff;
  font-size: 1em;
  border-radius: 0.5em 0 0 0.5em;
  width: 30ch;
  overflow-y: visible;
  border-right: 1px solid var(--darker-primary-color);
}

.searchbar::before {
  content: "";
  display: block;
  background-image: url(../static/search.svg);
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 0.1em;
  width: 2em;
  height: 2em;
  z-index: 2;
}

.select-dropdown-list {
  background-color: #fff;
  box-shadow: 0px 0px 8px var(--darker-primary-color);
  margin-top: 0.5em;
  border-radius: 0.5em;
  transform: scaleY(0);
  z-index: 2;
  transition: transform 0.3s;
  transform-origin: top center;
}

.select-dropdown-list.open {
  transform: scaleY(1);
}

.select-dropdown ul {
  display: flex;
  justify-content: center;
  flex-direction: column;
  list-style-type: none;
  padding-left: 0px;
}

.select-dropdown li:hover {
  background-color: var(--lighter-primary-color);
}
