.popup-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 10;
}

.popup {
  margin-top: 150px;
  background-color: var(--primary-color);
  border-radius: 10px;
  width: 60vw;
}
