:root {
  --primary-color: #cdc6d2;
  --darker-primary-color: #747077;
  --lighter-primary-color: #f2eef5;
}

body {
  margin: 0;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
  height: 100vh;
}

#root {
  min-height: 100vh;
}
