.ColisTable {
  border-collapse: collapse;
  overflow-y: auto;
}

.ColisTable thead {
  background-color: #fafafa;
  font-weight: bold;
  position: sticky;
  top: 0px;
}

.ColisTable tr {
  border-bottom: 1px solid var(--darker-primary-color);
}

.ColisTable thead:after {
  content: "";
  background-color: var(--darker-primary-color);
  position: absolute;
  bottom: 0;
  left: 0;
  margin-top: 5px;
  width: 100%;
  height: 2px;
}

.ColisTable th {
  margin-right: 5px;
}

.ColisTable .yellow {
  background-color: #f8ee92;
}

.ColisTable .yellow:hover {
  background-color: #f7f3c7;
}

.ColisTable .yellow.selected {
  background-color: #f8e856;
}

.ColisTable .red {
  background-color: #eb8c8c;
}

.ColisTable .red:hover {
  background-color: #f1b1b1;
}

.ColisTable .red.selected {
  background-color: #e75e5e;
}

.Colis:hover {
  background-color: var(--lighter-primary-color);
}

.selected {
  background-color: var(--primary-color);
}

.selected:hover {
  background-color: var(--primary-color);
}

.ColisTable tr:last-of-type {
  border-bottom: none;
}
