.App {
  min-height: 100vh;
}

nav {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: var(--primary-color);
}

nav ul {
  display: flex;
  flex-direction: row;
  list-style-type: none;
}

nav ul li {
  padding: 5px;
}

nav a {
  font-size: 1.3em;
  color: var(--darker-primary-color);
  text-decoration: none;
  transition: 0.3s;
}

nav a:hover {
  color: #fafafa;
}

nav .current {
  color: #fafafa;
}
