.EditColis {
  background-color: var(--primary-color);
  border-radius: 10px;
  margin: 10px;
  padding: 30px;
  flex-shrink: 4;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.EditColis form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.EditColis h3 {
  text-align: left;
  margin-bottom: 5px;
}

.EditColis .separator {
  width: 30%;
  height: 2px;
  background-color: var(--darker-primary-color);
  margin-bottom: 15px;
}

.statut {
  font-size: 1.4em;
  font-weight: 600;
}

.warn {
  background-color: #f7e8e8;
  border-radius: 5px;
  border: 2px solid #f38a8a;
  max-width: 500px;
  padding: 5px;
  align-self: center;
}

.warning-text {
  color: #be3636;
  font-size: 0.8em;
  max-width: 20ch;
}

.button-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}
